import { CommandWrapper, CommandMenu, useCommands, useKmenu, Command } from "kmenu";
import React, { useEffect, useState, useRef } from "react";
import { useMapState } from "../MapDisplay";
import styled from "styled-components";
import { useGlobalState } from "../../Menu/GlobalState";
import { CategoryCommand } from "kmenu/dist/types";
import { MdOutlineFormatSize } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { message } from "antd";
import * as Commands from './Implementations';
import CommandTemplate, { CommandFunctionParameters } from "./CommandTemplate";
import { shallow } from "zustand/shallow";
import { DatabaseMapPoi } from "../../../types";
import { useMainMenuState } from "../../Menu/MainMenu";
import { Feature } from "ol";
import {useLocalStorage} from "usehooks-ts";
import {TextPoiColorModalOptions} from "../../Utility/ChangeTextPoiColorModal";

interface Props {
  openCommandPalette: boolean,
  setOpenCommandPalette: (b: boolean) => void,
}

const MenuParent = styled.div<{fullMenu: boolean, mousePositionX: number, mousePositionY: number }>`

  & .backdrop {
    background-color: ${props => props.fullMenu ? undefined : "#ffffff00 !important"};
    backdrop-filter: ${props => props.fullMenu ? undefined : 'none !important'};
    z-index: 999;
  }

  & .dialog {
    position: ${props => props.fullMenu ? undefined : 'absolute !important'};
    left: ${props => props.fullMenu ? undefined : props.mousePositionX + 'px !important'};
    top: ${props => props.fullMenu ? undefined : props.mousePositionY + 'px !important'};
    width: ${props => props.fullMenu ? undefined : '320px !important'};
    padding: ${props => props.fullMenu ? undefined : '0.2rem !important'};
    display: flex;
    flex-direction: column;
    max-height: 300px;
  }

  & .searchbar {
    padding: ${props => props.fullMenu ? undefined : '10px 5px !important'};
    font-size: ${props => props.fullMenu ? undefined : '16px !important'};
  }

  & .breadcrumb {
    color: white;
  }

  & .category_header {
    margin-bottom: ${props => props.fullMenu ? undefined : '0px !important'};
  }

  & .selected {
    height: ${props => props.fullMenu ? undefined : '28px !important'};
    border-radius: ${props => props.fullMenu ? undefined : '0px !important'};
  }

  & .command {
    height: ${props => props.fullMenu ? undefined : '28px !important'};
  }

  & .command_wrapper {
    padding-bottom: ${props => props.fullMenu ? undefined : '12px !important'};
    height: ${props => props.fullMenu ? undefined : '100% !important'};
  }
`;

export default function DynamicKMenu({openCommandPalette, setOpenCommandPalette}: Props) {
  const [commands, setCommands] = useCommands([]);
  const [nestedCommands, setNestedCommands] = useCommands([]);
  const {isOpen, toggle, input, setInput, setOpen} = useKmenu();

  const amGM = useMapState((state) => state.amGM);
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const originalGameTokens = useMainMenuState((state) => state.originalGameTokens);
  const mouseOverFeature = useMapState((state) => state.mouseOverFeature);
  const [openedWithMouseOverFeature, setOpenWithMouseOverFeature] = useState<DatabaseMapPoi | undefined>();
  const [openedWithHoveredDrawFeature, setOpenedWithHoveredDrawFeature] = useState<Feature | undefined>();
  const [fullMenu, setFullMenu] = useState(false);
  const [menuMousePos, setMenuMousePos] = useState<number[]>([0,0]);
  const anyModalOpen = useGlobalState((state) => state.anyModalOpen);
  const setModalOpen = useGlobalState((state) => state.setModalOpen);
  const rollDice = useGlobalState((state) => state.diceRoll);
  const session = useGlobalState((state) => state.session);
  const mouseOverRequestedMovement = useMapState((state) => state.mouseOverMovementApproval);
  const setPoiCoordinateChanges = useGlobalState((state) => state.setPoiCoordinateChanges);

  const allDatabaseMaps = useGlobalState((state) => state.allDatabaseMaps);
  const allDatabasePois = useGlobalState((state) => state.allDatabasePois);
  const setEditingMapId = useGlobalState((state) => state.setEditingMapId);
  const setMapReadyForDisplay = useMapState((state) => state.setMapReadyForDisplay);
  const setCurrentZoomFromOtherMap = useMapState((state) => state.setCurrentZoomFromOtherMap);
  const setForceMapMovePoi = useMapState((state) => state.setForceMapMovePoi);
  
  const [lastPoiFontSize, setLastPoiFontSize] = useLocalStorage<string>('last-poi-font-size', undefined);

  const [hoveredDrawFeature, setHoverDrawFeature] = useMapState((state) => [state.hoverDrawFeature, state.setHoverDrawFeature], shallow);
  const setForceMapDrawingSync = useMapState((state) => state.setForceMapDrawingSync);
  const myDrawSource = useMapState((state) => state.myDrawSource);
  const channels = useMapState((state) => state.channels);
  const setWorkingMoveArrows = useMapState((state) => state.setWorkingMoveArrows);
  
  const setPoiColorModalOptions = useMapState((state) => state.setPoiColorModalOptions);

  const [nestedCrumbs, setNestedCrumbs] = useState<string[]>(['Map']);

  const defaultPlaceholder = "What would you like to do or see?";
  const [nestedPlaceholder, setNestedPlaceholder] = useState<string>(defaultPlaceholder);
  const map = useMapState((state) => state.map);

  const inputRef = useRef<string>();
  inputRef.current = input;

  const [messageApi, contextHolder] = message.useMessage({ });

  const tupleRef = useRef<CommandFunctionParameters>();
  tupleRef.current = {
    amGM: amGM ?? false,
    mouseOverFeature: openedWithMouseOverFeature,
    mouseOverRequestedMovement: mouseOverRequestedMovement,
    setMouseOverFeature: setOpenWithMouseOverFeature,
    messageAPI: messageApi,
    setOpen: setOpen,
    currentText: inputRef,
    setInput: setInput,
    editingMapData: editingMapData,
    setEditingMapData: setEditingMapData,
    gameTokens: originalGameTokens,
    mousePosition: menuMousePos,
    diceRoll: rollDice,
    map: map,
    channels: channels,
    session: session,
    lastPoiFontSize: lastPoiFontSize,
    setLastPoiFontSize: setLastPoiFontSize,
    allDatabaseMaps: allDatabaseMaps,
    allDatabasePois: allDatabasePois,
    setEditingMapId: setEditingMapId,
    setMapReadyForDisplay: setMapReadyForDisplay,
    setCurrentZoomFromOtherMap: setCurrentZoomFromOtherMap,
    myDrawSource: myDrawSource,
    hoveredDrawFeature: openedWithHoveredDrawFeature,
    setHoveredDrawFeature: setHoverDrawFeature,
    setForceDrawingUpdate: setForceMapDrawingSync,
    setForceMapMovePoi: setForceMapMovePoi,
    setPoiCoordinateChanges: setPoiCoordinateChanges,
    setWorkingMoveArrows: setWorkingMoveArrows,

    setPoiColorModalOptions
  } as CommandFunctionParameters;

  const commandsTemplatesToCategories = (templates: CommandTemplate[], returnRaw?: boolean, fullMenu?: boolean) => {
    const byCategory: {[key: string]: CategoryCommand[]} = {};

    templates.sort((a,b) => (b.priority ?? 0) - (a.priority ?? 0)).forEach((template: CommandTemplate) => {
      if (template.shouldInclude && !template.shouldInclude({
        ...tupleRef.current,
        isFullMenu: fullMenu ?? false,
      }))
        return;

      if (!(template.category in byCategory))
        byCategory[template.category] = [];

      byCategory[template.category].push(mapTemplateToCommand(template, fullMenu));
    });

    if (returnRaw)
      return byCategory;

    const toReturn: Command[] = [];
    Object.keys(byCategory).forEach((key) => {
      toReturn.push({
        category: key,
        commands: byCategory[key]
      } as Command)
    })
    return toReturn;
  }

  const mapTemplateToCommand = (template: CommandTemplate, fullMenu?: boolean) => {
    return {
      icon: typeof template.icon === 'function' ? template.icon(tupleRef.current) : template.icon,
      text: typeof template.text === 'function' ? template.text(tupleRef.current) : template.text,
      keywords: template.keywords,
      closeOnComplete: template.closeOnComplete ?? false,
      alwaysShow: (template.alwaysShow === undefined ? false : (template.alwaysShow === true ? true : (template.alwaysShow === false ? false : template.alwaysShow(tupleRef.current)))),
      
      perform: () => {
        if (template.perform)
          template.perform(tupleRef.current);
        if (template.nestedTemplates)
        {
          let templates: CommandTemplate[] = undefined;
          if (typeof template.nestedTemplates === 'function')
            templates = template.nestedTemplates(tupleRef.current);
          else
            templates = template.nestedTemplates;
          setNestedCommands(commandsTemplatesToCategories(templates, false, fullMenu) as Command[]);
        }
        if (template.nestedBreadcrumbs)
          setNestedCrumbs(template.nestedBreadcrumbs);
        if (template.nestedPlaceholder)
          setNestedPlaceholder(template.nestedPlaceholder);
        if (template.nestedTemplates)
          setOpen(2);
      }
    } as CategoryCommand
  }

  const buildCommandsForMenu = (fullMenu: boolean) => {
    console.log('building commands');
    const byCategory: {[key: string]: CategoryCommand[]} = {};

    Object.keys(Commands).forEach((type) => {
      // @ts-ignore
      const theseCommands = Commands[type];

      const result = commandsTemplatesToCategories(theseCommands, true, fullMenu) as {[key: string]: CategoryCommand[]};
      Object.keys(result).forEach((key: string) => {
        if (key in byCategory)
          byCategory[key].push(...result[key]);
        else
          byCategory[key] = result[key];
      })
    })

    const toReturn: Command[] = [];
    Object.keys(byCategory).forEach((key) => {
      toReturn.push({
        category: key,
        commands: byCategory[key]
      } as Command)
    })
    return toReturn;
  };

  useEffect(() => {
    if (!openCommandPalette)
      return;

    setOpenCommandPalette(false);

    if (isOpen())
      return;

    setFullMenu(true);
    setCommands(buildCommandsForMenu(true));
    toggle();
  }, [openCommandPalette]);

  useEffect(() => {
    setModalOpen('commands', isOpen());
  }, [isOpen]);
  
  useEffect(() => {
    const listen = (e: MouseEvent) => {
      if (anyModalOpen)
        return;
      e.preventDefault();
      e.stopImmediatePropagation();

      if (isOpen())
        return;

      let offset = [e.clientX, e.clientY];

      if (mouseOverFeature?.is_image) {
        // This is a token, lets offset the position to the right a little bit to account for that.
        const tokenWidthHeight = mouseOverFeature.font_override ? parseFloat(mouseOverFeature.font_override) * 250 : editingMapData.default_token_size * 250;

        offset = map.getPixelFromCoordinate([mouseOverFeature.coordinate_x + tokenWidthHeight / 2, mouseOverFeature.coordinate_y - tokenWidthHeight / 2]);
      }

      setCommands(buildCommandsForMenu(false));
      setMenuMousePos(offset);
      setFullMenu(false);
      toggle();
    };

    window.addEventListener('contextmenu', listen);

    return () => {
      window.removeEventListener('contextmenu', listen);
    }
  }, [mouseOverFeature, isOpen, anyModalOpen]);

  useEffect(() => {
    if (isOpen())
      return;
    
    setOpenWithMouseOverFeature(mouseOverFeature);
    setOpenedWithHoveredDrawFeature(hoveredDrawFeature);
  }, [mouseOverFeature, hoveredDrawFeature, isOpen]);

  return (
    <MenuParent fullMenu={fullMenu} mousePositionX={menuMousePos[0] ?? 0} mousePositionY={menuMousePos[1] ?? 0}>
      {contextHolder}
      {/* @ts-ignore */}
      <div className={fullMenu ? 'fullmenu' : 'minimenu'}>
        <CommandWrapper>
          <CommandMenu commands={commands} index={1} crumbs={['Map']} placeholder={defaultPlaceholder}/>

          <CommandMenu commands={nestedCommands} index={2} crumbs={nestedCrumbs} placeholder={nestedPlaceholder}/>
        </CommandWrapper>
      </div>
    </MenuParent>
  )
}