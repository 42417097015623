import { PropsWithChildren } from 'react';
import {Helmet} from "react-helmet";

// import Navbar from '@/components/ui/Navbar';
// import Footer from '@/components/ui/Footer';

import { PageMeta } from '../types';
import * as React from "react";
import Background from './Background/Background';

interface Props extends PropsWithChildren {
  meta?: PageMeta,
  scrollDisabled: boolean,
}

export default function Layout({ children, meta: pageMeta, scrollDisabled }: Props) {
  const meta = {
    title: 'Maps for Tabletop',
    description: 'Brought to you by Vercel, Stripe, and Supabase.',
    cardImage: '/og.png',
    ...pageMeta
  };

  return (
    <>
      <Helmet style={[{
        "cssText": `
            .ant-layout-sider {
              overflow: auto !important;
            }

            .ListContent {
              overflow: auto !important;
            }

            .ant-list {
              overflow: hidden auto !important;
            }

            body {
              margin: 0px;
              overflow: ${scrollDisabled ? 'hidden !important' : 'auto'};
              background-color: ${!scrollDisabled ? '#0d0d0d !important' : 'unset'};

              -webkit-user-select: none; /* Safari */
              -ms-user-select: none; /* IE 10 and IE 11 */
              user-select: none; /* Standard syntax */
            }

            .map-container {
                width: 100%;
                height: 100%;
            }

            .constrainChildren > * {
              max-width: 100%;
              max-height: 100%;
            }
            
            .ant-list-item {
              display: flex;
            }

            .ant-list-item-main {
              flex-grow: 8 !important;
            }

            .ant-list-item-extra {
              flex-grow: 1;
            }

            .extraSection > div {
              width: 100%;
            }

            .extraSection .ant-space-item .ant-space-item {
              width: 50%;
            }

            .extraSection .ant-space-item .ant-space {
              width: 100%;
            }

            .remirror-theme .ProseMirror {
              border-radius: 4px;
              box-shadow: rgba(255, 255, 255, 0.25) 0px 0px 0px 1px !important;
            }
            
            .remirror-theme .ProseMirror[contenteditable="false"] {
              border-radius: 0px;
              box-shadow: unset !important;
            }

            .remirror-theme .ProseMirror:hover {
              box-shadow: rgb(60, 137, 232) 0px 0px 0px 1px !important;
            }

            .remirror-theme .ProseMirror:hover[contenteditable="false"] {
              box-shadow: unset !important;
            }
            
            .remirror-theme.readonly .ProseMirror {
              border-radius: 4px;
              box-shadow: unset !important;
            }

            .remirror-theme.readonly .ProseMirror:hover {
              box-shadow: unset !important;
            }

            .remirror-theme.readonly .remirror-resizable-view div {
              display: none !important;
            }

            .remirror-theme .ProseMirror > * {
              margin-top: 4px !important;
              margin-bottom: 4px !important;
            }

            .remirror-theme .ant-float-btn-body {
              background-color: hsl(0 0% 24% / 1);
            }

            .remirror-theme .inline-float-group button {
              background-color: hsl(0 0% 24% / 1);
            }

            .inline-float-group .ant-float-btn-group-square .ant-float-btn-square:not(:last-child) {
              border-right: 1px solid rgba(253, 253, 253, 0.12);
              border-bottom: unset !important;
            }

            .inline-float-group .ant-float-btn-group-square .ant-float-btn-square:first-child {
              border-start-end-radius: unset !important;
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;
            }

            .inline-float-group .ant-float-btn-group-square .ant-float-btn-square:last-child {
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
              border-end-start-radius: unset !important;
            }
            
            .inline-float-group .ant-float-btn-group-square .ant-float-btn-square:only-child {
              border-radius: 8px !important;
            }

            .inline-float-group .ant-float-btn-group {
              position: inherit;
              display: flex;
              width: 100%;
            }

            /* CSS for kmenu*/
            * {
              box-sizing: border-box;
            }
            
            .backdrop {
              display: flex;
              flex-direction: column;
              align-items: center;
              overflow: hidden;
              position: fixed;
              top: 0;
              left: 0;
              z-index: 50;
              width: 100vw;
              height: 100vh;
              -webkit-user-select: none;
                -moz-user-select: none;
                      user-select: none;
            }
            
            .dialog {
              padding: 0.5rem;
              width: 640px;
              transition: 0.1s ease 0s;
              will-change: height;
              position: fixed;
              border-style: solid;
              top: 20%;
            }
            
            .crumbs {
              display: flex;
              margin: 5px 0px 0px 5px;
            }
            
            .breadcrumb {
              border: none;
              outline: none;
              padding: 5px 10px;
              margin-right: 7px;
              cursor: pointer;
            }
            
            .searchbar {
              border: none;
              padding: 20px 10px;
              margin: 0 0 8px;
              background-color: transparent;
              font-size: 18px;
              width: 100%;
              outline: 0;
              font-family: inherit;
            }
            
            .command_wrapper {
              display: flex;
              overflow-y: scroll !important;
              overflow-x: hidden;
              flex-direction: column;
              width: 100%;
              transition: height 0.1s ease 0s;
              will-change: height;
            }
            
            .command {
              display: flex;
              justify-content: space-between;
              position: relative;
              height: 54px;
              font-size: 1.125rem;
              line-height: 1.75rem;
              align-items: center;
              text-decoration: none;
              cursor: pointer;
              transition: 0.1s linear;
            }
            
            .shortcuts > kbd {
              padding: 3px 6px;
              border-radius: 3px;
              font-size: 14px;
              margin-right: 7px;
              font-family: monospace;
            }
            
            .shortcuts {
              display: flex;
              align-items: center;
              justify-content: center;
              width: -moz-fit-content;
              width: fit-content;
            }
            
            .info_wrapper {
              display: flex;
              align-items: center;
              position: relative;
              width: 100%;
              height: 100%;
              margin-left: 20px;
            }
            
            .info_wrapper > svg {
              width: 20px;
              height: 20px;
              margin-right: 12px;
            }
            
            .category_header {
              margin: 5px 0 10px 10px;
              font-size: 14px;
            }
            
            .selected {
              position: absolute;
              width: 100%;
              height: 54px;
              border-radius: 0.5rem;
            }
            
            .command_text {
              max-width: 90%;
              width: -moz-fit-content;
              width: fit-content;
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 16px;
            }
            
            .scroll_ref {
              margin-top: 20px;
            }
            
            @media only screen and (max-width: 700px) {
              .dialog {
                width: 90%;
              }
            }

            .ant-collapse-header:has(div[showdrop='show']) {
              border: 2px dashed cyan;
            }

            .ant-collapse-content:has(div[showdrop='show']) {
              border: 2px dashed cyan;
            }
          

            .je-object-editor{
              background: white;
              max-height:300px;
              width:500px;
              border:1px solid #d3d3d3;
              border-radius:8px;
              overflow:auto;
              font-family:monospace;
              font-size:11.2px;
              text-align:left;
              padding-top:6px;
              padding-left:6px
            }
            .je-object-editor .je-item-block{
                padding-left:6px
            }
            .je-object-editor .je-sub-items{
                margin-left:-6px;
                padding-left:15px;
                border-left:1px dashed grey;
                border-bottom:1px dashed #000;
                padding-bottom:3px;
                margin-bottom:3px
            }
            .je-object-editor .je-sub-items.closed{
                display:none
            }
            .je-object-editor .je-item-name{
                margin-right:5px;
                color:#00008b
            }
            .je-object-editor .je-url-display{
                text-decoration:underline;
                color:#ff4500
            }
            .je-object-editor .je-icon{
                line-height:1.2rem;
                font-size:14px;
                vertical-align:middle;
                cursor:pointer;
                opacity:.6;
                padding-right:.2rem;
                font-weight:700;
                margin-left:2px
            }
            .je-object-editor .je-launch-url{
                font-size:20px
            }
            .je-object-editor .je-launch-url:after{
                color:#ff4500;
                font-weight:700;
                display:inline-block;
                content:\"\\279A\"
            }
            .je-object-editor .je-add-new-item:after{
                color:green;
                font-weight:700;
                display:inline-block;
                content:\"\\002B\"
            }
            .je-object-editor .je-remove-item:after{
                color:red;
                font-weight:700;
                display:inline-block;
                content:\"\\00D7\"
            }
            .je-object-editor .je-add-item-text,.je-object-editor .je-item-value.null,.je-object-editor .je-item-value.undefined{
                color:#855a00;
                font-style:italic
            }
            .je-object-editor .je-item-value.array,.je-object-editor .je-item-value.object{
                color:#000
            }
            .je-object-editor .je-item-value.array .array-count,.je-object-editor .je-item-value.object .object-curly{
                color:#00f;
                cursor:pointer
            }
            .je-object-editor .je-item-value.string{
                color:green
            }
            .je-object-editor .je-item-value.boolean{
                color:#00f
            }
            .je-object-editor .je-item-value.date{
                color:green;
                background-color:rgba(0,0,0,.05)
            }
            .je-object-editor .je-expander{
                margin-left:-9px;
                line-height:1.2rem;
                font-size:.7rem;
                vertical-align:middle;
                cursor:pointer;
                opacity:.6;
                padding-right:.2rem
            }
            .je-object-editor .je-expander:after{
                display:inline-block;
                transition:-webkit-transform .1s ease-in;
                transition:transform .1s ease-in;
                transition:transform .1s ease-in,-webkit-transform .1s ease-in;
                content:\"\\25BA\"
            }
            .je-object-editor .je-expander.open:after{
                -webkit-transform:rotate(90deg);
                transform:rotate(90deg)
            }
            .je-object-editor .je-name-editor-input,.je-object-editor .je-value-editor-input{
                padding:0;
                border:none;
                font-family:monospace;
                font-size:12px;
                min-width:220px;
                max-width:400px;
                max-height:380px
            }
            .je-object-editor .je-name-editor-input{
                min-width:100px;
                max-width:200px
            }

            .ant-notification-notice:has(.roll) {
                width: 25vw;
            }

            .ant-notification-notice:has(.roll) .ant-notification-notice-message {
                margin-bottom: 0px;
            }

            .fullmenu .dialog {
              max-height: none !important;
            }

            table td:has(.featurerow) {
              background-color: #1d1d1d !important;
            }
            
            .colormodal .ant-modal {
              width: fit-content !important;
              transform-origin: unset !important;
              position: absolute;
              top: 0px;
            }
            
            .colormodal .ant-modal-content {
              padding: 0px;
              width: fit-content;
            }
            
            .ant-tour {
              z-index: 999999 !important;            
            }
            
            .ant-tour-mask {
              z-index: 99999 !important;
            }
        `
      }]}>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index"/>
        <link href="/favicon.ico" rel="shortcut icon"/>
        <meta content={meta.description} name="description"/>
        <meta
          property="og:url"
          content={`https://subscription-starter.vercel.app`}
        />
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content={meta.title}/>
        <meta property="og:description" content={meta.description}/>
        <meta property="og:title" content={meta.title}/>
        <meta property="og:image" content={meta.cardImage}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@vercel"/>
        <meta name="twitter:title" content={meta.title}/>
        <meta name="twitter:description" content={meta.description}/>
        <meta name="twitter:image" content={meta.cardImage}/>

        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link href="https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap"
              rel="stylesheet"/>
      </Helmet>
      <Background/>
      <main id="skip" style={{overflow: 'hidden'}} onContextMenu={(e) => {
        e.preventDefault();
      }}>{children}</main>
    </>
  );
}
